@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';

$font-size: 0.85rem;
$background-color: #fafafa;

// Zoom level (height of time grid row)
.fc-time-grid .fc-slats td {
  height: 2.8em;
}

// Time axis time position
td.fc-axis.fc-time.fc-widget-content > span {
  position: relative;
  top: -1.4rem;
}

// Event
.fc-event {
  font-size: $font-size;
  // font-weight: 400;
  border-width: 0.1px;
  border-color: #fff;
  border-radius: 3px;
  border-style: solid !important;
  padding-left: 0.2rem;
}

.fc-time-grid-event .fc-time,
.tooltip-date,
.tooltip-small-text {
  font-size: 0.7rem;
}

// All-Day event max height (to make multi-line tasks take up one line only)
.fc-day-grid-event.fc-event {
  max-height: 19px;
}

// Time axis
.fc-axis.fc-time.fc-widget-content {
  font-size: $font-size;
  text-align: center;
}

// Hide 00:00
tr[data-time='00:00:00'] > td.fc-axis.fc-time.fc-widget-content > span {
  visibility: hidden;
}

// More (e.g. +5) link
a.fc-more {
  font-size: 1rem;
}

// Now indicator
.fc-ltr .fc-time-grid .fc-now-indicator-arrow {
  left: 2.3rem;
  border-style: none;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: #ea4335;
  z-index: 1000;
}

// Prevent issue where can hover over now indicator changing cursor
// and if clicking adds new event instead of selecting event under it
.fc-time-grid .fc-now-indicator-line {
  pointer-events: none;
}

// Current day background colour
.fc-unthemed td.fc-today {
  background-color: $background-color;
}

// Clear all borders
.fc th,
.fc td,
.fc .fc-row {
  border-style: none;
  border-width: 0px;
}

.fc-divider {
  display: none;
}

// Spacing/sizing adjustments
.fc-timeGrid-view .fc-day-grid .fc-row .fc-content-skeleton {
  padding-bottom: 0.4em;
}

// Box shadow below All Day section
div.fc-day-grid {
  box-shadow: 0px 7px 3px -5px rgba(0, 0, 0, 0.2);
}

// Vertical borders in All Day section
div.fc-day-grid
  > div.fc-row.fc-week.fc-widget-content
  > div.fc-bg
  > table
  > tbody
  > tr
  > td:nth-child(n + 2) {
  border-left-style: solid !important;
  border-left-width: 1px;
  border-color: #ddd;
}

// Vertical borders in timed section
div.fc-time-grid > div.fc-bg > table > tbody > tr > td:nth-child(n + 2) {
  border-left-style: solid !important;
  border-left-width: 1px;
}

// Horizontal borders in month view
div.fc-dayGridMonth-view
  div.fc-day-grid
  > div.fc-row.fc-week.fc-widget-content
  > div.fc-bg
  > table
  > tbody
  > tr
  > td {
  border-top-style: solid !important;
  border-top-width: 1px;
  border-color: #ddd;
}

// Horizontal hourly border
div.fc-scroller.fc-time-grid-container
  > div.fc-time-grid
  > div.fc-slats
  > table
  > tbody
  > tr:not(.fc-minor)
  > td:nth-child(2) {
  border-top-style: solid !important;
  border-top-width: 1px;
}

// Resize selector mobile
.fc-time-grid-event.fc-selected .fc-resizer {
  bottom: 1px;
}

// Resize selector mobile selectable area
.fc-event.fc-selected .fc-resizer:before {
  height: 30px;
  margin-top: -25px;
}

// Allday event height
.fc-day-grid-event.fc-event {
  padding-top: 3px;
  padding-bottom: 3px;
}

// Select "mirror" timed
.fc-time-grid-event.fc-event.fc-mirror {
  background-color: rgb(97, 97, 97);
}

// Select "highlight" Allday
.fc-highlight {
  background-color: rgb(97, 97, 97);
  opacity: 0.8;
  border-style: solid !important;
  border-width: 0.5px;
  border-color: #ffffff;
  border-radius: 3px;
  z-index: 10;
}

// Drag/drop highlight from task list onto calendar
.fc-time-grid .fc-highlight-container {
  z-index: 5;
}

// Header styling
th.fc-day-header {
  font-size: 1.25rem;
  font-weight: 400;
  border-left-style: solid !important;
  border-left-width: 1px;
}

div.fc-timeGridDay-view th.fc-day-header {
  text-align: left;
  padding-left: 0.3rem;
}

// Today styling
th.fc-day-header.fc-today > span,
td.fc-day-top.fc-today > span {
  color: #4285f4;
  font-weight: bold;
}
