.sortable-dragging {
  background-color: #fafafa;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  z-index: 5;
}

.sortable-dragging > div::after {
  border-bottom: 0;
}

.waves-effect.waves-effect-color .waves-ripple {
  background-color: rgba(65, 131, 241, 0.4);
}

.waves-effect.waves-white .waves-ripple {
  background-color: #fafafa;
}

div.tippy-tooltip {
  font-size: 0.85rem;
}

div.tooltip-date-time {
  margin-bottom: 0.15rem;
}

div.tooltip-title {
  margin-bottom: 0.2rem;
}

span.tooltip-tag {
  color: #fff;
  padding: 0.1rem;
  margin-right: 0.2rem;
  border-radius: 0.2rem;
  font-size: 0.7rem;
}
